import React from "react";
import styles from "./styles.module.scss";
import { ImCross } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { closeVideoBackdrop } from "../../../redux/actions/backdrops";

export default function VideoBackdrop() {
  const dispatch = useDispatch();
  const { videoBackdropStatus, selectedVideo } = useSelector(
    (state) => state.backdrops
  );

  const handleCloseBackdropVideo = () => {
    dispatch(closeVideoBackdrop());
  };

  return (
    <>
      {videoBackdropStatus && (
        <div className={styles.popup} onClick={handleCloseBackdropVideo}>
          <ImCross onClick={handleCloseBackdropVideo} />
          <video
            src={`${process.env.REACT_APP_SPACES_IMAGE_URL}${selectedVideo}`}
            muted
            autoPlay
            controls
          ></video>
        </div>
      )}
    </>
  );
}
