import {
  RETRIEVE_USERS,
  RETRIEVE_USERS_AGE,
  RETRIEVE_USERS_RISK,
  RETRIEVE_LOGGEDIN_USER,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  RESTORE_USER,
  UPDATE_USER_STATUS,
  VERIFY_TAX_INFO,
  LOGIN_SUCCESS,
  RETRIEVE_QUERY,
  RETRIEVE_USERS_NETWORTH,
  LOGINUSER,
  LOGOUT,
} from "./types";
import UserService from "../services/user.service";
import userService from "../services/user.service";
import { toast } from "react-toastify";

export const retrieveUsers =
  (
    roleId,
    keyword = "",
    page = "",
    perPage = "",
    excludeId,
    filterUser,
    all = true,
    active = true,
    isStepCompleted = "2",
    isExistingUser = "2"
  ) =>
  async (dispatch) => {
    try {
      const res = await UserService.getAll(
        roleId,
        keyword,
        page,
        perPage,
        excludeId,
        filterUser,
        all,
        active,
        isStepCompleted,
        isExistingUser
      );
      dispatch({
        type: RETRIEVE_USERS,
        payload: res.data.listUsers,
      });
    } catch (err) {
      console.log(err);
    }
  };

// get age filtered user
export const retrieveAgeUsers =
  (
    roleId,
    keyword = "",
    page = "",
    perPage = "",
    excludeId,
    filterUser,
    all = true,
    active = true,
    isStepCompleted = "2",
    isExistingUser = "2",
    age = ""
  ) =>
  async (dispatch) => {
    try {
      const res = await UserService.getAllAge(
        roleId,
        keyword,
        page,
        perPage,
        excludeId,
        filterUser,
        all,
        active,
        isStepCompleted,
        isExistingUser,
        age
      );
      dispatch({
        type: RETRIEVE_USERS_AGE,
        payload: res.data.listUsers,
      });
    } catch (err) {
      console.log(err);
    }
  };

// get risk filtered user
export const retrieveRiskUsers =
  (
    roleId,
    keyword = "",
    page = "",
    perPage = "",
    excludeId,
    filterUser,
    all = true,
    active = true,
    isStepCompleted = "2",
    isExistingUser = "2",
    risk = ""
  ) =>
  async (dispatch) => {
    try {
      const res = await UserService.getAllRisk(
        roleId,
        keyword,
        page,
        perPage,
        excludeId,
        filterUser,
        all,
        active,
        isStepCompleted,
        isExistingUser,
        risk
      );
      dispatch({
        type: RETRIEVE_USERS_RISK,
        payload: res.data.listUsers,
      });
    } catch (err) {
      console.log(err);
    }
  };

// get net worth filtered user
export const retrieveNetWorthUsers =
  (
    roleId,
    keyword = "",
    page = "",
    perPage = "",
    excludeId,
    filterUser,
    all = true,
    active = true,
    isStepCompleted = "2",
    isExistingUser = "2",
    netWorth = ""
  ) =>
  async (dispatch) => {
    try {
      const res = await UserService.getAllNetWorth(
        roleId,
        keyword,
        page,
        perPage,
        excludeId,
        filterUser,
        all,
        active,
        isStepCompleted,
        isExistingUser,
        netWorth
      );
      dispatch({
        type: RETRIEVE_USERS_NETWORTH,
        payload: res.data.listUsers,
      });
    } catch (err) {
      console.log(err);
    }
  };

// export const retrieveLoggedInUser = (id) => async (dispatch) => {
//   const res = await UserService.get(id);
//   try {
//     dispatch({
//       type: RETRIEVE_LOGGEDIN_USER,
//       payload: { user: res.data.userInfo },
//     });

//     dispatch({
//       type: LOGINUSER,
//       payload: { user: res.data.userInfo },
//     });

//     dispatch({
//       type: LOGIN_SUCCESS,
//       payload: { user: res.data.userInfo },
//     });
//   } catch (err) {
//     console.log(err);
//   }
// };

export const retrieveLoggedInUser = (id) => async (dispatch) => {
  try {
    const res = await UserService.get(id);

    dispatch({
      type: RETRIEVE_LOGGEDIN_USER,
      payload: { user: res.data.userInfo },
    });

    dispatch({
      type: LOGINUSER,
      payload: { user: res.data.userInfo },
    });

    dispatch({
      type: LOGIN_SUCCESS,
      payload: { user: res.data.userInfo },
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      console.error("Session expired or unauthorized. Redirecting to login...");

      localStorage.removeItem("_oluo");

      dispatch({
        type: LOGOUT,
      });

      window.location.href = "/login"; // Or use history.push("/login") if using React Router
      toast("Your session has expired. Please log in again.", {
        type: "error",
        autoClose: 5000,
        position: "top-right",
      });
    } else {
      console.error("An error occurred:", err);
    }
  }
};

export const createUser = (data) => async (dispatch) => {
  try {
    const res = await UserService.create(data);

    dispatch({
      type: CREATE_USER,
      payload: res.data.userInfo,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateUser = (id, data) => async (dispatch) => {
  try {
    const res = await UserService.update(id, data);

    dispatch({
      type: UPDATE_USER,
      payload: res.data.userInfo,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// update risk Score

export const updateRiskScore = (data, id) => async (dispatch) => {
  try {
    const res = await UserService.updateRiskScore(data, id);
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateUserStatus = (id, data) => async (dispatch) => {
  try {
    const res = await UserService.updateStatus(id, data);

    dispatch({
      type: UPDATE_USER_STATUS,
      payload: res.data.listUsers,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const verifyUserTaxInfo = (id, data) => async (dispatch) => {
  try {
    const res = await UserService.verifyUserTaxInfo(id, data);

    dispatch({
      type: VERIFY_TAX_INFO,
      payload: res.data.userInfo,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const deleteUser = (id, data) => async (dispatch) => {
  try {
    const res = await UserService.deleteUser(id, data);

    dispatch({
      type: DELETE_USER,
      payload: res.data.listUsers,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const restoreUser = (id, data) => async (dispatch) => {
  try {
    const res = await UserService.restore(id, data);

    dispatch({
      type: RESTORE_USER,
      payload: res.data.listUsers,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const retriveCustomerQuery =
  (filterText, page, perPage) => async (dispatch) => {
    try {
      const res = await UserService.getCustomerQuery(filterText, page, perPage);

      dispatch({
        type: RETRIEVE_QUERY,
        payload: res.data.list,
      });
    } catch (err) {
      console.log(err);
    }
  };

export const deleteCustomerQuery = async (id, callBack) => {
  try {
    const res = await userService.deleteCustomerQuery(id);

    if (res.status == 200) {
      callBack(res);
    }
  } catch (error) {
    console.log(error);
  }
};
