import {
  RETRIEVE_PLANS,
  GET_USER_SUBSCRIPTION,
  ASSIGN_PLAN_TO_USER,
} from "../actions/types";

const initialState = {
  plans: [],
  userSubscription: null,
  assignedPlan: null,
  totalCount: 0,
};

const planDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_PLANS:
      return {
        ...state,
        plans: payload.rows,
        totalCount: payload.count,
      };
    case GET_USER_SUBSCRIPTION:
      return {
        ...state,
        userSubscription: payload,
      };
    case ASSIGN_PLAN_TO_USER:
      return {
        ...state,
        assignedPlan: payload,
      };
    default:
      return state;
  }
};

export default planDetailsReducer;
