import forge from "node-forge";
import RSA_KEY from "./RSA";
import { setUser } from "../redux/actions/auth";
import { store } from "../config/configureStore";

export const decrypt = async (keys) => {
  const { encryptedAESKey, userData, iv } = keys;

  // Function to decrypt AES key with RSA using node-forge
  function decryptAESKeyWithRSA(encryptedAESKey, privateKey) {
    try {
      const privateKeyForge = forge.pki.privateKeyFromPem(privateKey);
      const encryptedBuffer = forge.util.decode64(encryptedAESKey);
      const decrypted = privateKeyForge.decrypt(encryptedBuffer, "RSA-OAEP");
      return decrypted;
    } catch (error) {
      console.error("Error decrypting AES key:", error);
      throw error;
    }
  }

  // Function to decrypt data with AES
  function decryptWithAES(encryptedData, key, iv) {
    try {
      const decipher = forge.cipher.createDecipher("AES-CBC", key);
      decipher.start({ iv: forge.util.decode64(iv) });
      decipher.update(
        forge.util.createBuffer(forge.util.decode64(encryptedData))
      );
      const result = decipher.finish();
      if (!result) {
        throw new Error("AES decryption failed");
      }
      const decrypted = decipher.output.toString("utf8");
      return decrypted;
    } catch (error) {
      console.error("Error decrypting data with AES:", error);
      throw error;
    }
  }

  // Check if all required keys are present
  if (encryptedAESKey && userData && iv) {
    try {
      const decryptedAESKey = decryptAESKeyWithRSA(encryptedAESKey, RSA_KEY);
      const decryptedData = decryptWithAES(userData, decryptedAESKey, iv);
      const parsedData = JSON.parse(decryptedData);

      store.dispatch(setUser(parsedData));
      return parsedData;
    } catch (error) {
      console.error("Error decrypting data:", error);
    }
  } else {
    console.error("Missing encryption data", { encryptedAESKey, userData, iv });
  }
};
