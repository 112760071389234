import {
  DELETE_SUBSCRIPTION,
  GET_FEATURES_LIST,
  GET_SUBSCRIPTION_LIST,
  RESTORE_SUBSCRIPTION,
  GET_SUBSCRIPTION_BY_ID,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_STATUS,
} from "../actions/types";

const initialState = {
  subscription: [],
  features: [],
  subscriptionList: [],
  count: 0,
};

const subscriptionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case GET_SUBSCRIPTION_LIST:
      return {
        ...state,
        subscription: payload.rows,
        count: payload.count,
      };
    case GET_FEATURES_LIST:
      return {
        ...state,
        features: payload.data.rows,
      };
    case DELETE_SUBSCRIPTION:
      return {
        ...state,
        subscription: state.subscription.filter((sub) => sub.publicId !== payload.publicId),
      };

    case RESTORE_SUBSCRIPTION:
      return {
        ...state,
        subscription: payload,
      };
    case GET_SUBSCRIPTION_BY_ID:
      return {
        ...state,
        subscriptionList: payload,
      };
      case UPDATE_SUBSCRIPTION:
        return {
          ...state,
          subscription: state.subscription.map((sub) =>
            sub.publicId === payload.publicId ? { ...sub, ...payload } : sub
          ),
        };
        case UPDATE_SUBSCRIPTION_STATUS:
          return {
            ...state,
            subscription: state.subscription.map((sub) =>
              sub.publicId === payload.publicId ? { ...sub, ...payload } : sub
            ),
          };
          
    default:
      return state;
  }
};

export default subscriptionReducer;
