const user = JSON.parse(localStorage.getItem("_oluo"));
let token = "";

if (user && user.token) {
  token = user.token;
}

export const authHeader = () => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return headers;
};

export const pdfHeader = () => {
  const headers = {
    "Content-Type": "application/pdf",
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return headers;
};

export const multipartHeader = () => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return headers;
};

export default { authHeader, multipartHeader, pdfHeader };
