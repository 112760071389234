import subscriptionService from "../services/subscription.service";
import {
  GET_SUBSCRIPTION_LIST,
  GET_FEATURES_LIST,
  DELETE_SUBSCRIPTION,
  RESTORE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_STATUS,
  GET_SUBSCRIPTION_BY_ID,
} from "./types";

export const retrieveSubscription =
  (params = { page: 1, perPage: 10, keyword: "" }) =>
  async (dispatch) => {
    try {
      const res = await subscriptionService.getSubscriptions(params);
      dispatch({
        type: GET_SUBSCRIPTION_LIST,
        payload: {
          rows: res.data.data.rows,
          count: res.data.data.count,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

export const retrieveSubscriptionById = (publicId) => async (dispatch) => {
  try {
    const res = await subscriptionService.getSubscriptionById(publicId);
    dispatch({
      type: GET_SUBSCRIPTION_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getFeatures = (isActive, planFor) => async (dispatch) => {
  try {
    const res = await subscriptionService.getFeatures(isActive, planFor);
    dispatch({
      type: GET_FEATURES_LIST,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteSubscription = (publicId, data) => async (dispatch) => {
  try {
    const res = await subscriptionService.deleteSubscription(publicId, data);
    dispatch({
      type: DELETE_SUBSCRIPTION,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const restoreSubscription = (publicId, data) => async (dispatch) => {
  try {
    const res = await subscriptionService.restoreSubscription(publicId, data);

    dispatch({
      type: RESTORE_SUBSCRIPTION,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateSubscription = (data) => async (dispatch) => {
  try {
    const res = await subscriptionService.updateSubscription(data);
    dispatch({
      type: UPDATE_SUBSCRIPTION,
      payload: res.data.planInfo,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateSubscriptionStatus = (publicId, data) => async (dispatch) => {
  try {
    const res = await subscriptionService.updateSubscriptionStatus(publicId, data);
    dispatch({
      type: UPDATE_SUBSCRIPTION_STATUS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
