export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGINUSER = "LOGINUSER";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const RETRIEVE_CAPABILITY = "RETRIEVE_CAPABILITY";
export const UPDATE_CAPABLITY = "UPDATE_CAPABLITY";

export const RETRIEVE_USERS = "RETRIEVE_USERS";
export const RETRIEVE_USERS_AGE = "RETRIEVE_USERS_AGE";
export const RETRIEVE_USERS_RISK = "RETRIEVE_USERS_RISK";
export const RETRIEVE_USERS_NETWORTH = "RETRIEVE_USERS_NETWORTH";
export const RETRIEVE_LOGGEDIN_USER = "RETRIEVE_LOGGEDIN_USER";
export const CREATE_USER = "UPDATE_USER";
export const UPDATE_USER = "CREATE_USER";
export const DELETE_USER = "DELETE USER";
export const RESTORE_USER = "RESTORE_USER";
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
export const VERIFY_TAX_INFO = "VERIFY_TAX_INFO";

export const RETRIEVE_COUNTRIES = "RETRIEVE_COUNTRIES";
export const RETRIEVE_STATES = "RETRIEVE_STATES";
export const RETRIEVE_CITIES = "RETRIEVE_CITIES";

export const RETRIEVE_SPACES = "RETRIEVE_SPACES";
export const UPDATE_SPACE_STATUS = "UPDATE_SPACE_STATUS";
export const UPDATE_IS_ACTIVE = "UPDATE_IS_ACTIVE";
export const DELETE_SPACE = "DELETE_SPACE";
export const RESTORE_SPACE = "RESTORE_SPACE";

export const SHOW_IMAGE_BACKDROP = "SHOW_IMAGE_BACKDROP";
export const CLOSE_IMAGE_BACKDROP = "CLOSE_IMAGE_BACKDROP";
export const SHOW_VIDEO_BACKDROP = "SHOW_VIDEO_BACKDROP";
export const CLOSE_VIDEO_BACKDROP = "CLOSE_VIDEO_BACKDROP";

export const RETRIEVE_PAGES = "RETRIEVE_PAGES";
export const GET_PAGE_DETAIL = "GET_PAGE_DETAIL";
export const CREATE_PAGE = "CREATE_PAGE";
export const UPDATE_PAGE = "UPDATE_PAGE";
export const DELETE_PAGE = "DELETE_PAGE";
export const RESTORE_PAGE = "RESTORE_PAGE";
export const UPDATE_PAGE_STATUS = "UPDATE_PAGE_STATUS";

export const RETRIEVE_STORAGE_TYPES = "RETRIEVE_STORAGE_TYPES";
export const CREATE_STORAGE_TYPE = "CREATE_STORAGE_TYPE";
export const UPDATE_STORAGE_TYPE = "UPDATE_STORAGE_TYPE";
export const DELETE_STORAGE_TYPE = "DELETE_STORAGE_TYPE";
export const RESTORE_STORAGE_TYPE = "RESTORE_STORAGE_TYPE";
export const UPDATE_STORAGE_TYPE_STATUS = "UPDATE_STORAGE_TYPE_STATUS";

export const RETRIEVE_SOURCES = "RETRIEVE_SOURCES";
export const CREATE_SOURCE = "CREATE_SOURCE";
export const UPDATE_SOURCE = "UPDATE_SOURCE";
export const DELETE_SOURCE = "DELETE_SOURCE";

export const RETRIEVE_REVIEWS = "RETRIEVE_REVIEWS";
export const DELETE_REVIEW = "DELETE_REVIEW";
export const RESTORE_REVIEW = "RESTORE_REVIEW";
export const UPDATE_REVIEW_STATUS = "UPDATE_REVIEW_STATUS";

export const RETRIEVE_RESERVATIONS = "RETRIEVE_RESERVATIONS";
export const UPDATE_RESERVATION_STATUS = "UPDATE_RESERVATION_STATUS";
export const UPDATE_PAYOUT_HOLD_STATUS = "UPDATE_PAYOUT_HOLD_STATUS";

export const RETRIEVE_TRANSACTIONS = "RETRIEVE_TRANSACTIONS";
export const RETRIEVE_RENTER_TRANSACTIONS = "RETRIEVE_RENTER_TRANSACTIONS";
export const REFUND_AMOUNT = "REFUND AMOUNT";

export const RETRIEVE_REFUNDS = "RETRIEVE_REFUNDS";

export const RETRIEVE_PAYOUTS = "RETRIEVE_PAYOUTS";
export const RETRIEVE_HOST_PAYOUTS = "RETRIEVE_HOST_PAYOUTS";

export const UPDATE_SETTINGS = "UPDATE_SETTINGS";

export const UPDATE_BECOME_A_HOST = "UPDATE_BECOME_A_HOST";

export const RETRIEVE_STEPS = "RETRIEVE_STEPS";
export const RETRIEVE_STEP_TWO = "RETRIEVE_STEP_TWO";
export const UPDATE_STEPS = "UPDATE_STEPS";
export const RETRIEVE_STEP_FIVE = "RETRIEVE_STEP_FIVE";
export const RETRIEVE_STEP_SIX = "RETRIEVE_STEP_SIX";
export const RETRIEVE_STEP_SEVEN = "RETRIEVE_STEP_SEVEN";
export const RETRIEVE_STEP_FOUR = "RETRIEVE_STEP_FOUR";
export const RETRIEVE_STEP_THREE = "RETRIEVE_STEP_THREE";
export const RETRIEVE_QUERY = "RETRIEVE_QUERY";
export const RETRIVE_BALANCE_SHEET = "RETRIVE_BALANCE_SHEET";

export const GET_CLIENT_ADVISOR = "GET_CLIENT_ADVISOR";

export const CREATE_OPPORTUNITY = "UPDATE_OPPORTUNITY";
export const UPDATE_OPPORTUNITY = "CREATE_OPPORTUNITY";
export const DELETE_OPPORTUNITY = "DELETE OPPORTUNITY";
export const RESTORE_OPPORTUNITY = "RESTORE_OPPORTUNITY";
export const RETRIEVE_OPPORTUNITY = "RETRIEVE_OPPORTUNITY";

export const GET_COMPANY_DETAIL = "GET_COMPANY_DETAIL";
export const RETRIEVE_CURRENT_COMPANY = "RETRIEVE_CURRENT_COMPANY";
export const SET_USER_PERMISSION = "SET_USER_PERMISSION";
export const CLEAR_SESSION = "CLEAR_SESSION";

export const GET_SUBSCRIPTION_LIST = "GET_SUBSCRIPTION_LIST";
export const GET_COMPANY_DETAIL_ERROR = "GET_COMPANY_DETAIL_ERROR";
export const GET_FEATURES_LIST = "GET_FEATURES_LIST";
export const DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
export const RESTORE_SUBSCRIPTION = "RESTORE_SUBSCRIPTION";
export const GET_SUBSCRIPTION_BY_ID = "GET_SUBSCRIPTION_LIST_BY_ID";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION_STATUS = "UPDATE_SUBSCRIPTION_STATUS";


export const RETRIEVE_INQUIRY = "RETRIEVE_INQUIRY";
export const RETRIEVE_INQUIRY_DETAILS = "RETRIEVE_INQUIRY_DETAILS";
export const UPDATE_INQUIRY_DETAILS = "UPDATE_INQUIRY_DETAILS";

export const RETRIEVE_ACTIVITY_LOGS = "RETRIEVE_ACTIVITY_LOGS";

export const RETRIEVE_PLANS = "RETRIEVE_PLANS";
export const GET_USER_SUBSCRIPTION = "GET_USER_SUBSCRIPTION";
export const ASSIGN_PLAN_TO_USER = "ASSIGN_PLAN_TO_USER";

export const GET_CONTACTS = "GET_CONTACTS";
export const GET_CONTACT_DETAILS = "GET_CONTACT_DETAILS";
export const DELETE_CONTACT = "DELETE_CONTACT";


