import { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Row, Col, Card, CardBody, CardFooter } from 'reactstrap';
import PageContainer from "../Layout/PageContainer";
import subscriptionService from '../../redux/services/subscription.service';
import { useDispatch, useSelector } from 'react-redux';
import { getFeatures } from '../../redux/actions/subscription';
import { toast, Slide } from "react-toastify";
import { useNavigate } from 'react-router-dom';

toast.configure();
const SubscriptionAdd = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    planFor: '',
    title: '',
    description: '',
    isPopular: false,
    frequency: '',
    price: '',
    featureIds: [],
  });

  const [userType, setUserType] = useState({ planFor: '' });
  const [filteredFeatures, setFilteredFeatures] = useState([]);
  const dispatch = useDispatch();
  const { features } = useSelector((state) => state?.subscriptionReducer)
  const [ loading, setLoading ] = useState ( false );
  
  useEffect(() => { 
    dispatch(getFeatures(1))
  }, [dispatch])
  
  // Filtering features whenever `planFor` changes
  useEffect(() => {
    if (features && userType.planFor !== '') {
      const filtered = features.filter(
        (feature) => feature.featureFor === userType.planFor
      );
      setFilteredFeatures(filtered);
    } else {
      setFilteredFeatures([]);  // Reset filtered features when planFor is empty
    }
  }, [userType.planFor, features]);
  

  // Handle checkbox change
  const handleCheckboxChange = (publicId) => {
    let updatedBenefits = [...formData.featureIds];

    if (updatedBenefits.includes(publicId)) {
      // Remove publicId if already checked
      updatedBenefits = updatedBenefits.filter((id) => id !== publicId);
    } else {
      // Add publicId if not already in featureIds
      updatedBenefits.push(publicId);
    }

    // Update formData with updated featureIds array
    setFormData({
      ...formData,
      featureIds: updatedBenefits,
    });
  };

  const handleFrom = (e) => {
    formData.planFor = e.target.value;
    setUserType({ ...userType, planFor: e.target.value });
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? (checked ? 1 : 0) : value,
    });
  };


  // Submit form Data
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    subscriptionService.createSubscription(formData)
    .then((res) => {
        setLoading(false);
        if(res.status === 200) {
          toast(res.data.message, {
            transition: Slide,
  
            closeButton: true,
  
            autoClose: 3000,
  
            position: "top-right",
  
            type: "success", // info/success/warning/error
          });
          navigate('/subscription/list')
        }else {
          toast(res.data.message, {
            transition: Slide,
  
            closeButton: true,
  
            autoClose: 3000,
  
            position: "top-right",
  
            type: "error", // info/success/warning/error
          });
        }
    },(err) => {
      toast(err.response.data.message, {
        transition: Slide,

        closeButton: true,

        autoClose: 3000,

        position: "top-right",

        type: "error", // info/success/warning/error
      });
      console.log(err)
    })
    .catch((err) => {
      setLoading(false);
      console.log(err)
    })
  };
  //#endregion

  return (
    <PageContainer
      pageTitleIcon="pe-7s-shuffle icon-gradient bg-plum-plate"
      pageHeading="Create Subscription"
      pageSubTitle="Create Subscription for Talent & Company"
    >
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <Form onSubmit={handleSubmit}>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label for="price">Select Plan Type</Label>
                      <Input
                        type="select"
                        name="planFor"
                        id="planFor"
                        value={userType.planFor}
                        onChange={(e) => handleFrom(e)}
                      >
                        <option value=" ">Select Plan Type</option>
                        <option value="0">Talent Plan</option>
                        <option value="1">Company Plan</option>
                      </Input>

                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="frequency">Select Billing Cycle</Label>

                      <Input
                        type="select"
                        name="frequency"
                        id="frequency"
                        value={formData.frequency}
                        onChange={handleChange}
                      >
                        <option value="0">Select Billing Cycle</option>
                        <option value="1">Monthly Plan</option>
                        <option value="2">Yearly Plan</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="price">Price</Label>
                      <Input
                        type="number"
                        name="price"
                        id="price"
                        placeholder="Ex: 1000"
                        value={formData.price}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="title">Plan Title</Label>
                      <Input
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Ex: Demo Plan"
                        value={formData.title}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="isPopular">Popular Plan</Label>
                      <Input
                        type="select"

                        name="isPopular"
                        id="isPopular"
                        // checked={formData.isPopular === 1}
                        onChange={handleChange}
                      >
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="plantitle">Description</Label>
                      <Input
                        type="textarea"
                        name="description"
                        id="description"
                        placeholder="Ex: Demo Plan"
                        value={formData.description}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    {filteredFeatures.length > 0 ? (
                      filteredFeatures.map((feature, i) => (
                        <FormGroup key={i}>
                          <Label for={`benefits_${i}`}>{feature.title}</Label>
                          <Input
                            type="checkbox"
                            name={`benefits_${i}`}
                            id={`benefits_${i}`}
                            className='mx-2'
                            onChange={() => handleCheckboxChange(feature.publicId)}
                            checked={formData.featureIds.includes(feature.publicId)}
                          />
                        </FormGroup>
                      ))
                    ) : (
                      <span>Please Select plan type to see available features</span>
                    )}
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="d-block">
                <Button type="button" color="link" onClick={() => console.log("Cancel")}>
                  Cancel
                </Button>
                <Button color="primary" size="lg" disabled={loading}>
                  {loading && (<span className="spinner-border spinner-border-sm"></span>)}
                    {!loading && ('Submit') } 
                  </Button>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default SubscriptionAdd;
