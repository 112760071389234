import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DropdownToggle,
  DropdownMenu,
  Nav,
  Button,
  NavItem,
  UncontrolledButtonDropdown,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-toastify/dist/ReactToastify.css";
import { retrieveLoggedInUser } from "../../../redux/actions/users";
//import logout action
import { logout } from "../../../redux/actions/auth";
//import event bus
import EventBus from "../../../common/EventBus";
import defaultProfile from "../../../assets/utils/images/avatars/user.png";
import { Link } from "react-router-dom";

const UserBox = (props) => {
  const dispatch = useDispatch();

  const { user: authDetails } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state?.users);
  // console.log(authDetails.publicId , props)

  // useEffect(()=>{
  //   // console.log("state Data",state?.auth?.user?.user?.publicId)
  //   //import users action
  // },[state])

  const [currentUser, setCurrentUser] = useState({});

  const [avatar, setAvatar] = useState("");

  const id = props?.authDetails?.publicId;

  useEffect(() => {
    if (id) {
      dispatch(retrieveLoggedInUser(id));
    }
  }, [dispatch, id, props?.publicId]);

  useEffect(() => {
    if (user !== undefined) setCurrentUser(user);

    if (user && user.expiry && new Date().getTime() > user.expiry) logOut();
    return;
  }, [user]);

  useEffect(() => {
    //set profile image preview when userDetail changes
    if (currentUser?.profileImage) {
      setAvatar(
        process.env.REACT_APP_API_URL + "profile/" + currentUser?.profileImage
      );
    } else {
      setAvatar(
        // process.env.REACT_APP_API_URL + "profile/user.png"
        defaultProfile
      );
    }
  }, [currentUser]);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, [logOut]);

  return (
    <>
      <div className="header-btn-lg pe-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <UncontrolledButtonDropdown>
                <DropdownToggle color="link" className="p-0">
                  <img
                    width={42}
                    className="rounded-circle"
                    src={avatar}
                    alt=""
                  />
                  <FontAwesomeIcon
                    className="ms-2 opacity-8"
                    icon={faAngleDown}
                  />
                </DropdownToggle>
                <DropdownMenu end className="rm-pointers dropdown-menu-lg">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-info">
                      <div className="menu-header-image opacity-2" />
                      <div className="menu-header-content text-start">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left me-3">
                              <img
                                width={42}
                                className="rounded-circle"
                                src={avatar}
                                alt=""
                              />
                            </div>
                            <div className="widget-content-left">
                              <div className="widget-heading">
                                {currentUser && currentUser.name !== null
                                  ? currentUser.name
                                  : "Admin"}
                              </div>
                              <div className="widget-subheading opacity-8">
                                {currentUser ? currentUser.email : ""}
                              </div>
                            </div>
                            <div className="widget-content-right me-2">
                              <Button
                                className="btn-pill btn-shadow btn-shine"
                                color="focus"
                                onClick={logOut}
                              >
                                Logout
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="scroll-area-xs p-2"
                    style={{
                      height: "38px",
                    }}
                  >
                    <PerfectScrollbar>
                      <Nav vertical>
                        <NavItem>
                          <Link to="/profile">Edit My Profile</Link>
                        </NavItem>
                      </Nav>
                    </PerfectScrollbar>
                  </div>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserBox;
