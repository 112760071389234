import React, { useState } from "react";
import { InputGroup, Button, Input, Row, Col, Tooltip } from "reactstrap";
import { Label } from "recharts";
import Select from 'react-select';

const FilterComponent = ({ filterText, onFilter, onClear, dropDown = false, isSelectVisible = false, onSelect, onSelect2, refresh = false, onRefresh }) => {
  
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    // Handle the selected value here
    console.log('Selected option:', selectedOption);
  };

  const handleClear = () => {
    setSelectedOption(null);
  };

  const options = [
    { value: 'option1', label: 'OLUO 1' },
    { value: 'option2', label: 'OLUO 2' },
    { value: 'option3', label: 'OLUO 3' },
    // Add more options as needed
  ];
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  return (
    <>

      <Row style={{ width: "100%" }} className="d-flex justify-content-end">

        {/* {
        dropDown ?
          <Col md="6" className="mb-2 d-flex flex-md-row flex-row-reverse">
            <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>

              <label>Steps Completed</label>

              <Input
                type="select"
                id="isActive"
                name="isActive"
                onChange={onSelect}
                style={{ height: "30px", width: "50%" }}
              >
                <option >--Select--</option>
                <option value="1">YES</option>
                <option value="0">NO</option>
                <option value="2">BOTH</option>
              </Input>
            </div>
            <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>

              <label>Existing User</label>

              <Input
                type="select"
                id="isActive"
                name="isActive"
                onChange={onSelect2}
                style={{ height: "30px", width: "50%" }}
              >
                <option >--Select--</option>
                <option value="1">YES</option>
                <option value="0">NO</option>
                <option value="2">BOTH</option>
              </Input>
            </div>
          </Col> : ""
      }

      {
        refresh ? 

        <Col md="6">
          <Button id="syncButton" size="lg" color="primary" onClick={onRefresh} >
                    Sync Advisor
                  </Button>
                  <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target="syncButton"
        toggle={toggleTooltip}
      >
        This button will sync the data from hubspot to Arkos global
      </Tooltip>
        </Col> : ""
      } */}

        {/* <Col md={`${dropDown ? "6" : "6"}`} className="d-flex justify-content-end">
        <InputGroup size="sm" className="mb-3 " style={{ width: `${dropDown ? "40%" : "40%"}` }}>
          <Input
            id="search"
            type="text"
            placeholder="Search..."
            value={filterText}
            onChange={onFilter}
          />
          <Button color="primary" onClick={onClear}>
            <i className="pe-7s-close" style={{ cursor: "pointer", fontWeight: "bold" }}></i>
          </Button>
        </InputGroup>
      </Col> */}
   {isSelectVisible && <Col md="2">
      <InputGroup size="sm" className="mb-3">
        <Select
          id="search"
          placeholder="Select..."
          value={selectedOption}
          onChange={handleSelectChange}
          options={options}
          isClearable={true}
        />
        <Button color="primary" onClick={handleClear}>
          <i className="pe-7s-close" style={{ cursor: "pointer", fontWeight: "bold" }}></i>
        </Button>
      </InputGroup>
    </Col>}
        <Col md="3" >
          <InputGroup size="sm" className="mb-3 ">
            <Input
              id="search"
              type="text"
              placeholder="Search..."
              value={filterText}
              onChange={onFilter}
            />
            <Button color="primary" onClick={onClear}>
              <i className="pe-7s-close" style={{ cursor: "pointer", fontWeight: "bold" }}></i>
            </Button>
          </InputGroup>
        </Col>
       
      </Row>




    </>
  )
};

export default FilterComponent;
