import React, { useState, useMemo } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import DataTable from "react-data-table-component";
import PageContainer from "../Layout/PageContainer";
import * as Ionicons from "react-icons/io";
import IconContainer from "../Common/IconContainer";
import FilterComponent from "../../helpers/FilterComponent";

const EditIcon = Ionicons["IoIosCreate"];

export default function FormulaResult() {
  // Local state for pagination
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

//   const debounceSearch = useCallback(
//     debounceFunction((nextValue) => {
//       setFilterText(nextValue);
//       setPage(1);
//     }, 1000),
//     []
//   );

  //subheader component of react-data-table
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
    //   if (filterText) {
    //     setResetPaginationToggle(!resetPaginationToggle);
    //     setFilterText("");
    //     fetchFormulas({ page, perPage, keyword: "" });
    //   }
    };

    return (
      <FilterComponent
        // onFilter={(event) => {
        //   setFilterText(event.target.value);
        //   debounceSearch(event.target.value);
        // }}
        onClear={handleClear}
        // filterText={filterText}
      />
    );
  }, []);

  // Dummy data for the table
  const dummyData = [
    {
      id: 1,
      talentName: "John Doe",
      designation: "Software Engineer",
      email: "johndoe@example.com",
      phoneNumber: "123-456-7890",
    },
    {
      id: 2,
      talentName: "Jane Smith",
      designation: "Product Manager",
      email: "janesmith@example.com",
      phoneNumber: "987-654-3210",
    },
    {
      id: 3,
      talentName: "Mike Johnson",
      designation: "UI/UX Designer",
      email: "mikejohnson@example.com",
      phoneNumber: "555-123-4567",
    },
    {
      id: 4,
      talentName: "Emma Brown",
      designation: "Data Scientist",
      email: "emmabrown@example.com",
      phoneNumber: "111-222-3333",
    },
  ];

  // Columns configuration for DataTable
  const columns = useMemo(
    () => [
      {
        name: "Talent Name",
        selector: (row) => row.talentName,
        sortable: true,
      },
      {
        name: "Designation",
        selector: (row) => row.designation,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "Phone Number",
        selector: (row) => row.phoneNumber,
        sortable: true,
      },
      {
        name: "Action",
        cell: (row) => (
          <>
            <EditIcon id="edit-icon" style={{ display: "none" }} />

            <IconContainer
              id="edit-icon"
              Icon={EditIcon}
              //   handleOnClick={() => handleEdit(row.publicId)}
              text={"Edit"}
            />
          </>
        ),
      },
    ],
    []
  );

  return (
    <PageContainer
      pageTitleIcon="pe-7s-culture icon-gradient bg-plum-plate"
      pageHeading="Formula Results"
      pageSubTitle="List of talents with their details"
    >
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <DataTable
                columns={columns}
                data={dummyData}
                pagination
                paginationPerPage={perPage}
                paginationServer
                paginationTotalRows={dummyData.length}
                onChangePage={(page) => setPage(page)}
                onChangeRowsPerPage={(newPerPage) => setPerPage(newPerPage)}
                subHeader
                subHeaderComponent={subHeaderComponent}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
}
