import {
  GET_CONTACTS,
  GET_CONTACT_DETAILS,
  DELETE_CONTACT,
} from "../actions/types";

const initialState = {
  contactList: [],
  details: {},
  totalCount: 0,
};

const contactReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CONTACTS:
      return {
        ...state,
        contactList: payload.rows,
        totalCount: payload.count,
      };

    case GET_CONTACT_DETAILS:
      return {
        ...state,
        details: action.payload,
      };

    case DELETE_CONTACT:
      return {
        ...state,
        contactList: state.contactList.filter((contact) => contact.id !== action.payload),
      };

    default:
      return state;
  }
};

export default contactReducer;
