import { RETRIEVE_INQUIRY  ,RETRIEVE_INQUIRY_DETAILS, UPDATE_INQUIRY_DETAILS} from "../actions/types";
  
const initialState = { inquiry : [], details:[], totalCount : 0 };

const InquiryReducer = (state = initialState, action) => {

  const { type, payload } = action;

  switch (type) {

    case RETRIEVE_INQUIRY:
      return {
        ...state,
        inquiry: payload.rows,
        totalCount: payload.count,
    };
    case RETRIEVE_INQUIRY_DETAILS:
        return {
          ...state,
          details: payload,
      };

      case UPDATE_INQUIRY_DETAILS:
        if (state.details && state.details.publicId === payload.publicId) {
          return {
            ...state,
            details: {
              ...state.details,
              reply: payload.reply,
            },
          };
        }
        return state;
  
    default:
      return state;
  }
};

export default InquiryReducer;