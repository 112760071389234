/* eslint-disable import/no-anonymous-default-export */
import { authHeader } from "./auth-header";
import api from "../../common/api";

const createSubscription = (data) => {
  return api.post(process.env.REACT_APP_API_URL + `plan`, data, {
    headers: authHeader(),
  });
};

const getSubscriptions = (params = { page: 1, perPage: 10, keyword: "" }) => {
  return api.get(process.env.REACT_APP_API_URL + "plans", {
    headers: authHeader(),
    withCredentials: true,
    params,
  });
};

const getSubscriptionById = (publicId) => {
  return api.get(process.env.REACT_APP_API_URL + `plans/${publicId}`, {
    headers: authHeader(),
  });
};

const getFeatures = (Isactive, planFor) => {
  return api.get(process.env.REACT_APP_API_URL + `features`, {
    headers: authHeader(),
    params: {
      Isactive,
      planFor,
    },
  });
};

const deleteSubscription = (publicId, data) => {
  return api.delete(process.env.REACT_APP_API_URL + `plans/${publicId}`, {
    headers: authHeader(),
    data,
  });
};

const restoreSubscription = (publicId, data) => {
  return api.get(process.env.REACT_APP_API_URL + `plans/restore/${publicId}`, {
    headers: authHeader(),
    params: data,
  });
};

const updateSubscription = (data) => {
  return api.put(process.env.REACT_APP_API_URL + `plans/${data.publicId}`, data, {
    headers: authHeader(),
  });
};

const updateSubscriptionStatus = (data, publicId) => {
  return api.put(process.env.REACT_APP_API_URL + `plans/status/${publicId}`, data, {
    headers: authHeader(),
  });
};

export default {
  createSubscription,
  getSubscriptions,
  getSubscriptionById,
  getFeatures,
  deleteSubscription,
  restoreSubscription,
  updateSubscription,
  updateSubscriptionStatus
};
