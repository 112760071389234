import { RETRIEVE_ACTIVITY_LOGS } from "../actions/types";

const initialState = { activityLogs: [], totalRows: 0 };

const activityReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_ACTIVITY_LOGS:
      return {
        ...state,
        activityLogs: payload.rows,
        totalRows: payload.count,
      };

    default:
      return state;
  }
};

export default activityReducer;
